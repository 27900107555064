/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.primary-color{
    @apply text-transparent bg-clip-text bg-gradient-to-r from-orange-400 to-violet-600 inline-block
}
.btn-primary, .btn-outline {
    transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.3s ease;
}

.btn-primary:hover {
    background: linear-gradient(to right, #f97316, #ec4899); 
}

.btn-outline:hover {
    background-color: #4a5568;
    border-color: #2d3748; 
}

.btn-primary:hover, .btn-outline:hover {
    transform: scale(1.05);
}
/* Add this to your CSS file or in a <style> tag */
.glass-effect {
    background: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
    backdrop-filter: blur(10px); /* Adjust the blur intensity */
    -webkit-backdrop-filter: blur(10px); /* For Safari */
    border-radius: 8px; /* Adjust border radius as needed */
    border: 1px solid rgba(255, 255, 255, 0.2); /* Optional border to enhance the glass effect */
}

